import React, { useCallback, useEffect, useRef, useState } from "react";
import { GetStaticPaths, GetStaticProps } from "next";
import { JobsRepository, LandingRepository, TagManagerRepository } from "@repositories";
import { initLandingContext, LandingContext } from "@context";
import { I18n, i18n, TFunction, withTranslation } from "next-i18next";

import { Country } from "#types/jobs";
import Head from "next/head";
import Landing from "@components/landing/content";
import MainContent from "@components/layout/mainContent.component";
import { TLanding, TLandingContext, TSettingsJobSite } from "#types/landing";
import breadcrumbSchema from "../../../jsonLdScripts/breadcrumbSchema";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import MetaTags from "@componentscommon/metaTags/metaTags.component";
import { EEventsGA4, EPageType } from "@enums/landing.enum";
import { TDescription } from "#types/metaTags";
import { reloadMainProps } from "@utils/common";
import { isNonEmptyArray } from "ramda-adjunct";
import { jsonDataLayer, viewItemListSectionFromRoute } from "@utils/googleTagManager";
import { backendErrorHandler } from "@utils/common/backendErrorHandler";

type ComposedProps = { t: TFunction };

type LandingPageProps = ComposedProps & TLandingContext;

const LandingPage: React.FC<LandingPageProps> = ({ t, ...props }) => {
  const [contextValue, setContextValue] = useState({ ...initLandingContext, ...props });
  const { vacanciesModule } = props.landingPage as TLanding;
  const { generalMenuSettings } = props.settingsJobSite as TSettingsJobSite;
  const { videos, vacantsSubtitleText } = vacanciesModule;
  const { thirdOptionName: alternativeListTName } = generalMenuSettings;
  const nextRouter = useRouter();
  const tagManagerEventCalled = useRef(false);

  const navigationComplete = useCallback(() => {
    setContextValue({ ...initLandingContext, ...props });
  }, [props]);

  useEffect(() => {
    if (!tagManagerEventCalled.current) {
      isNonEmptyArray(videos) &&
        TagManagerRepository.viewItemList(
          jsonDataLayer(
            videos,
            vacantsSubtitleText || alternativeListTName || t("jobOffers:offer.jobOffers"),
            viewItemListSectionFromRoute(nextRouter),
            EEventsGA4.viewItemList
          )
        );
      tagManagerEventCalled.current = true;
    }
  }, [alternativeListTName, nextRouter, t, vacantsSubtitleText, videos]);

  useEffect(() => {
    reloadMainProps({ prevSlug: contextValue.serverCompanySlug, navigationComplete });
  }, [contextValue.serverCompanySlug, navigationComplete]);

  const companyName = {
    company: props.landingPage?.companyName
  };

  return (
    <React.Fragment>
      <Head>
        <MetaTags
          nextRouter={nextRouter}
          i18n={i18n as I18n}
          pageType={EPageType.companyPage}
          t={t}
          interpolationVars={companyName as TDescription}
        />
        <title>{t<string>("translation:companyPage.mainTitle", companyName)}</title>

        <meta name="robots" content="index,follow" />
        <meta name="title" content={t<string>("translation:companyPage.mainTitle", companyName)}></meta>
        <meta property="og:image" content="https://home.magneto365.com/wp-content/uploads/2022/08/screenshot.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={breadcrumbSchema(nextRouter)}
          key="landing-breadcrumb-jsonld"
        />
      </Head>
      <LandingContext.Provider value={{ ...contextValue, setContextValue }}>
        <MainContent type="landing">
          <Landing />
        </MainContent>
      </LandingContext.Provider>
    </React.Fragment>
  );
};

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: "blocking"
  };
};

export const getStaticProps: GetStaticProps<Partial<TLandingContext>> = async (context) => {
  if (!context.locale) throw new Error("locale is undefined");
  if (!context.params?.companySlug) throw new Error("companySlug is required");

  try {
    const [i18nProps, landingPage] = await Promise.all([
      serverSideTranslations(context.locale, ["jobOffers", "registration", "routes", "translation", "header"]),
      LandingRepository.getInfoLanding(context.params?.companySlug as string)
    ]);

    // fetch the company's settings and marks with the landing reposoitory
    const settingsJobSite = await LandingRepository.getSettingsJobSite({
      id: landingPage.companyId,
      countryId: landingPage.countryId
    });

    // TODO: review if this endpoint is good implemented
    const countries: Country[] = await JobsRepository.getCompanyCountries(context.params?.companySlug as string);

    return {
      revalidate: 600,
      props: {
        ...i18nProps,
        landingPage,
        countries,
        settingsJobSite,
        serverCompanySlug: context.params?.companySlug as string
      }
    };
  } catch (error) {
    return backendErrorHandler(error);
  }
};

export default withTranslation()(LandingPage);
